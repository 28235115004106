.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.603); /* Adjust opacity as desired */
    z-index: 0; /* Place it behind the text content (z-index: 1) */
  }
  .slide {
    animation: slideIn .6s ease-in-out;
}
  .slide1 {
    animation: slideIn 1s ease-in-out;
}
  .slide2 {
    animation: slideIn 1.4s ease-in-out;
}

@keyframes slideIn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

  
  .shade {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* transition: all .5s linear */
  }
  
  .modal {
    position: absolute;
    /* width: 500px;
    height: 300px;
    background: rgb(34, 34, 34);
    box-shadow: 1px 1px 15px 1px #444444;
    border-radius: 10px;
    padding: 15px */
  }
  
