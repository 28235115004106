.animated-title {
animation: slideInTitle 2s ease forwards;
}

.animated-left-to-right1 {
animation: slideInLeftToRight 1s ease forwards;
}
.animated-left-to-right2 {
animation: slideInLeftToRight 2s ease forwards;
}
.animated-left-to-right3 {
animation: slideInLeftToRight 3s ease forwards;
}
.animated-left-to-right4 {
animation: slideInLeftToRight 4s ease forwards;
}

@keyframes slideInLeftToRight {
0% {
    opacity: 0;
    transform: translateX(-40px);
}
100% {
    opacity: 1;
    transform: translateX(0);
}
}
