.popup {
    @media (display: none) { /* Hide the popup initially */
      display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    animation: fadeIn ease-in 0.3s;
  }
  
  .popup-content {
    background-color: white;
    margin: 50px auto;
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    animation: slideInUp ease-in 0.3s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideInUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  