@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import 'ionicons/dist/ionicons/ionicons.css'; */

@layer base{
  body{
    background-color: rgb(240, 240, 240);
    scroll-behavior: smooth;
    padding: 0px;
    margin: 0px;
    /* transition: .6s; */
    scrollbar-width: .5px;
    scrollbar-base-color: #db6f15;
    scrollbar-color: #f3e7dd;
    overflow-x: hidden;
  }
  
  
}
@layer components{
.text-container {
  /* Ensure text elements are visible */
  opacity: 1;
}
.text-transition {
  opacity: 0;
  transition: opacity 2s ease-in-out; /* Adjust the duration and easing function as needed */
}



.text-changing {
  /* Initial opacity for fade-in animation */
  opacity: 0;
}

  .footerBtn{
    transition: .4s;
  }
  .footerBtn:hover{
    color: rgb(51, 65, 85);
    transform: scale(1.1);
    background-color: white;
  }
  .skill{
    transition: .8s;
  }
  .skill:hover{
    transform: translateY(-12px);
  }
  .btnwork{
    transition: .7s;
    opacity: 1;
  }
  .btnwork:hover{
    opacity: 1;
    transform: scale(1.1);
  }
  .animated-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #db6f15;
    background-color: transparent;
    border: 1px solid #db6f15;
    transition: background-color 0.3s ease;
  }
  .work{
    opacity: 0;
    transition: .2s;
  }
  .tech{
    transition: .7s;
    cursor: pointer;
  }
  .title{
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .tech:hover{
    color: rgb(13 148 136 / 1);
    background-color: transparent;
    transform: scale(1.2);
    
  }
  .work:hover{
   
    background-color: black;
    opacity: .6;
  }

  .bt{
    transition: .4s;
    background-color: white;
  }
  .bt:hover{
    background-color: #db6f15;
    color: white;
  }
  .animated-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #db6f15 50%, #db6e15ab 50%);
    transition: left 1s ease;
  }

  .animated-button:hover {
    background-color: #db6f15;
    color: white;
  }
  .animated-button:hover::before {
    left: 100%;
  }
  .list{
    position: relative;
    text-decoration: none;
    padding: 0 14px;
    cursor: pointer;
    color: white;
  }
  .list::after{
    content: "";
    position: absolute;
    background-color: #db6f15;
    height: 2px;
    width: 0;
    left: 0;
    bottom: -5px;
    transition: 0.3s;
  }
  .list:hover{
    color: #db6f15;
  }
  .list:hover::after{
    width: 100%;
  }
}