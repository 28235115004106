.animated-title {
    animation: slideInTitle 2s ease forwards;
    }
    
    .animated-left-to-right {
    animation: slideInLeftToRight 2s ease forwards;
    }
    .animated-right-to-left {
    animation: slideInRightToLeft 2s ease forwards;
    }
    
    @keyframes slideInTitle {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    }
    
    @keyframes slideInLeftToRight {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    }
    @keyframes slideInRightToLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    }
    